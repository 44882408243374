import React from "react";
import parse from "html-react-parser";

const Html = (props) => {
  if (props.visibility !== "visible" || !props.content.length) {
    return (<></>);
  }
  const className = (() => {
    let cN = [];
    if (typeof props.layoutGridColumnSpan !== "undefined") {
      cN.push("col-span-"+props.layoutGridColumnSpan);
    }
    if (props.cssClass.length) {
      cN.push(props.cssClass);
    }
    if (cN.length) {
      return cN.join(" ");
    }
    return undefined;
  })();
  return <div className={ className }> { parse(props.content) } </div>;
};

export default Html;
